<template>
  <section id="dashboard-prediccion">
    <b-row>
      <b-col sm="12">
        <ag-grid-table ref="grid-historico-runs" :debug="true" :configUrl="historicoPlanificacionesConfig"
          :dataUrl="historicoPlanificacionesData" :editUrl="historicoPlanificacionesEdit" @gridReady="onGridReady"
          @getRowsLoaded="onGetRowsLoaded" :actions="actions" rowSelection="multiple"
          @selectionChanged="onSelectionChanged" :getRowNodeId="getRowNodeId" :helper="true">

          <template v-slot:actions>
            <div>
              <b-button-toolbar aria-label="Toolbar with button groups and input groups" justify>
                <b-button-group style="margin-bottom: 1rem">
                  <b-button size="sm" :disabled="disableCompareRuns" @click="comparePlanifications()" variant="primary">{{
                    $t('Comparar') }}</b-button>

                </b-button-group>
              </b-button-toolbar>
            </div>
          </template>

        </ag-grid-table>
      </b-col>
    </b-row>
  </section>
</template>

<script>

import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import useApiServices from '@/services/useApiServices.js';

// import HistoricoPlanificacionesActions from "./HistoricoPlanificacionesActions.vue";

import {
BRow,
BCol,

} from "bootstrap-vue";



export default {
  components: {
    AgGridTable,
    BRow,
    BCol,
    ToastificationContent

    // historicoPlanificacionesActions: HistoricoPlanificacionesActions

  },

  beforeUnmount() {
    //this.$pusher.unsubscribe('optimizations');

    if (this.timeoutId) {
      clearTimeout(this.timeoutId)
      this.timeoutId = null
    }


  },

  beforeRouteLeave(to, from, next) {

    //this.$pusher.unsubscribe('optimizations');

    if (this.timeoutId) {
      clearTimeout(this.timeoutId)
      this.timeoutId = null
    }

    next()
  },


  mounted() {

    let self = this;

    //Se escucha a la llegada de las notificaciones de pusher

    /*
    var channel = this.$pusher.subscribe('optimizations');

    channel.bind('OptimizationUpdateEvent', function (data)
    {
      console.log('OptimizationUpdateEvent', data)

      let userData = JSON.parse(localStorage.getItem("userData"));

      if (userData && userData.client.id == data.client_id)
      {
        let invocation_id = data.invocation_id

        useApiServices.getInvocation(invocation_id)
          .then((response) =>
          {
            let invocation = response.data

            console.log(invocation)

            if (self.tableLoaded && self.$refs['grid-historico-runs'])
            {
              self.$refs['grid-historico-runs'].updateRow(invocation_id, invocation)
            }



          })
          .catch(function (error) {
            console.log(error)
          })



      }


    });
    */

    /*
    if(!this.timeoutId)
    {
      this.timeoutId = setTimeout(
        () => {
          this.processNotFinished()
        }, 
        10000
      );
    }*/




  },


  methods: {

    processNotFinished() {
      //console.log("processNotFinished")

      let self = this

      let filteredNodes = self.$refs['grid-historico-runs'].filterNodes(node => ['RUNNING', 'STARTING', 'ERROR'].includes(node.data.status))

      let ids = filteredNodes.map(node => node.data.id);

      //console.log({filteredNodes, ids})

      useApiServices.getReadByIds(ids)
        .then(response => {

          response.data.forEach(invocation => {

            let invocation_id = invocation.id
            let invocation_updated_at = invocation.updated_at

            let rowNode = self.$refs['grid-historico-runs'].getRowNodeById(invocation_id)

            if (rowNode) {

              let row_node_updated_at = rowNode.data.updated_at

              let invocation_updated_at_date = Date.parse(invocation_updated_at)
              let row_node_updated_at_date = Date.parse(row_node_updated_at)

              if (invocation_updated_at_date > row_node_updated_at_date) {
                self.$refs['grid-historico-runs'].updateRow(invocation_id, invocation)
              }


            }



          })

          this.timeoutId = setTimeout(
            () => {
              this.processNotFinished()
            },
            this.timeoutDelay
          );

        })
        .catch(() => {

          this.timeoutId = setTimeout(
            () => {
              this.processNotFinished()
            },
            this.timeoutDelay
          );

        })
    },

    onSelectionChanged(selectedRows) {

      this.selectedRows = selectedRows

      this.disableCompareRuns = (selectedRows.length < 2)

    },

    comparePlanifications() {

      this.$router.push({ name: 'compare-optimizations', query: { ids: this.selectedRows.map(run => run.id) } })
    },


    accionVerDetalles(data) {

      console.log(data)


      let id = data.data.id;



      this.$router.push({ name: 'lorenzo-planification-detail', params: { planification_id: id } })

    },

    accionDescargar(data) {

      if (data.data.status == "FINISHED") {
        let id = data.data.id;
        let url = useApiServices.invocations.download + id
        useApiServices.getDownloadFile(url)
      }
      else {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Información`,
            icon: "DownloadIcon",
            variant: "info",
            html: `La planificación aún no está finalizada, en breve estará lista para descarga.`,
          },
        });
      }
    },


    onGridReady(gridApi, gridColumnApi, params) {

      console.log("Planificaciones > onGridReady", { gridApi, gridColumnApi, params })

      if (!this.timeoutId) {
        this.timeoutId = setTimeout(
          () => {
            this.processNotFinished()
          },
          this.timeoutDelay
        );
      }

    },

    onGetRowsLoaded(gridApi, gridColumnApi, params) {
      console.log("Planificaciones > onGetRowsLoaded", { gridApi, gridColumnApi, params })

      gridColumnApi.autoSizeColumn('actions')
      this.tableLoaded = true;


    },


  },

  data() {
    return {

      timeoutId: null,
      timeoutDelay: 10000,

      historicoPlanificacionesConfig: useApiServices.historicoPlanificacionesConfig,
      historicoPlanificacionesData: useApiServices.historicoPlanificacionesData,
      historicoPlanificacionesEdit: useApiServices.historicoPlanificacionesEdit,

      disableCompareRuns: true,
      selectedRows: [],

      tableLoaded: false,

      actions: [
        { name: "Ver Detalles", icon: "EyeIcon", action: this.accionVerDetalles },
        { name: "Descargar", icon: "DownloadIcon", action: this.accionDescargar },

      ],

      getRowNodeId: (params) => {
        return params.id;
      },


    };
  },
};
</script>
<style lang="scss" scoped>
span {
  font-size: 14px;
}
</style>
